<template>
  <div class="flex flex-col gap-2">
    <div v-if="filteredWorks.length" class="flex flex-col gap-2">
      <BackButton :route="{ name: 'Recurso'}" />
      <SeleccionTrabajoRow
        v-for="trabajo in filteredWorks"
        :key="trabajo.cliente"
        :actions="1"
        :trabajo="trabajo"
        @requireRefresh="filterWorks"
      />
      <SubmitButton
        text="ingresar trabajos del día"
        background="#ccc"
        color="black"
        :loading="loading"
        :dsabled="loading"
        @onClick="attemptSubmit"
      />
      <BlackModal
        v-if="showConfirmationModal"
        :text="loading ? 'procesando, espere' : 'estás seguro que deseas ingresar estos trabajos del día'"
        :hideActions="loading"
        @confirm="submit"
        @close="showConfirmationModal = false"
      />
    </div>
    <div v-else>
      <BlackModal
        text="Aún no has ingresado ningún trabajo del día"
        :success="false"
        failure-icon="StraightFace"
        @confirm="goBack"
        @close="goBack"
      />
    </div>
    <BlackModal
      v-if="showWorksAlreadySentModal"
      text="Ya has ingresado trabajos el dia de hoy"
      :success="false"
      @confirm="showWorksAlreadySentModal = false"
      @close="showWorksAlreadySentModal = false"
    />
    <BlackModal
      v-if="showSuccessModal"
      text="Ingreso Correcto"
      button-text="SEGUIR"
      @confirm="finalize"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BackButton from '@/components/shared/BackButton.vue';
import SubmitButton from '@/components/shared/SubmitButton.vue';
import BlackModal from '@/components/shared/BlackModal.vue';
import SeleccionTrabajoRow from '@/components/recurso/SeleccionTrabajoRow.vue';

export default {
  name: 'TrabajosDelDia',
  components: { BackButton, SubmitButton, BlackModal, SeleccionTrabajoRow },
  data() {
    return {
      loading: false,
      showConfirmationModal: false,
      showWorksAlreadySentModal: false,
      showSuccessModal: false,
      filteredWorks: [],
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/user',
      worksAssigned: 'works/worksAssigned'
    }),
  },

  mounted() {
    if (this.worksAssigned) {
      this.filterWorks();
    }
  },

  methods: {
    ...mapActions({
      setAsFinished: 'works/setAsFinish',
      setAsPartiallyFinished: 'works/setAsPartialFinish',
    }),

    filterWorks() {
      this.filteredWorks = this.worksAssigned.filter(work => work.status !== undefined);
    },

    attemptSubmit() {
      if (this.user.canSendWorks) {
        this.showConfirmationModal = true;
      } else {
        this.showWorksAlreadySentModal = true;
      }
    },

    async submit() {
      this.loading = true;

      for (const work of this.filteredWorks) {
        if (work.status === 'partiallyFinished') {
          await this.setAsPartiallyFinished(work.id);
        } else if (work.status === 'finished') {
          await this.setAsFinished(work.id);
        }
      }
      this.showConfirmationModal = false;
      this.showSuccessModal = true;
      this.loading = false;

    },

    finalize() {
      this.$router.push({ name: 'Recurso' });
    },

    goBack() {
      this.$router.push({ name: 'Trabajos' });
    }
  }
}
</script>
